<template>
  <div v-if="newSrNo !== null">
    <b-row v-if="newSrNo !== ''">
      <VueGlow
        class="mt-50 mx-1"
        color="red"
        fade
        interval="2"
        elevation="4"
        intense
        :intensity="2"
        v-if="blinking"
      >
        <b-badge
          class="badge-round cursor-pointer px-75 text-left"
          :style="
            highlight
              ? 'background-color: #c60000; white-space:normal; display:inline-flex'
              : 'background-color: rgba(197,0,1, 0.5); white-space:normal; display:inline-flex'
          "
        >
          <small
            class="p-0 m-0 text-white font-weight-bolder text-uppercase nowrap"
            >Curr. - Sr No.: {{ newSrNo.replace('Additional', 'Addl.') }}</small
          >
        </b-badge>
      </VueGlow>
      <b-badge
        v-else
        class="badge-round cursor-pointer px-75 text-left mt-50 mx-1"
        :style="
          highlight
            ? 'background-color: #c60000; white-space:normal; display:inline-flex'
            : 'background-color: #BEBEBE; white-space:normal; display:inline-flex'
        "
      >
        <small
          class="p-0 m-0 text-white font-weight-bolder text-uppercase nowrap"
          >Curr. - Sr No.: {{ newSrNo.replace('Additional', 'Addl.') }}</small
        >
      </b-badge>
    </b-row>
  </div>
</template>

<script>
import { BBadge, BRow } from 'bootstrap-vue'
import moment from 'moment'
import VueGlow from 'vue-glow'

export default {
  components: {
    BBadge,
    BRow,
    VueGlow
  },
  props: {
    date: {
      default: null
    },
    court_id: {
      default: null
    },
    court_no: {
      default: -1
    },
    serial_no: {
      default: -1
    }
  },
  data() {
    return {
      courtId: this.court_id,
      courtNo: this.court_no,
      serialNo: this.serial_no,
      newSrNo: null,
      cDate: this.date,
      highlight: true,
      blinking: false
    }
  },
  beforeMount() {},
  watch: {
    '$store.state.displayboardStore.userDisplayBoardData': function () {
      var cId = this.courtId
      if (
        Object.keys(this.$store.state.hearingStore.courtsDisplayBoard).includes(
          cId
        )
      ) {
        this.getData({
          db: this.$store.state.hearingStore.courtsDisplayBoard[cId]
        })
      }
    }
  },
  methods: {
    getData({ db }) {
      console.log('CDATE', this.courtNo)
      if (moment().format('DD-MM-YYYY') === this.cDate) {
        let nsr = []

        const court = db[0]
        const srNo = db[1]
        const list = db.length > 2 ? db[2] : []
        const col = db.length > 3 ? db[3] : []
        let index
        if (this.courtNo == -10) {
          index = court.indexOf('C.J.')
          console.log(index)
        } else if (this.courtNo == -15) {
          index = court.indexOf('In Chamber')
        } else {
          index = court.indexOf(String(this.courtNo))
        }

        console.log('Index', index)
        if (index >= 0) {
          if (
            srNo[index] !== 'Court NOT in session!' &&
            srNo[index] !== 'CourtNOTinsession!'
          ) {
            console.log(parseInt(srNo[index]), parseInt(this.serialNo))
            nsr.push(srNo[index])
            if (parseInt(srNo[index]) > parseInt(this.serialNo)) {
              this.highlight = false
            } else {
              this.highlight = true
            }
            if (parseInt(srNo[index]) > parseInt(this.serialNo)) {
              this.blinking = false
            } else {
              if (parseInt(srNo[index]) - 10 < parseInt(this.serialNo)) {
                this.blinking = false
              } else {
                this.blinking = true
              }
            }
          }
          if (list.length) {
            if (
              list[index] !== 'Court NOT in session!' &&
              list[index] !== 'CourtNOTinsession!'
            ) {
              nsr.push(list[index])
            }
          }
          if (col.length) {
            if (col[index]) {
              if (
                col[index] !== 'Court NOT in session!' &&
                col[index] !== 'CourtNOTinsession!'
              )
                nsr.push(col[index])
            }
          }
          if (nsr) {
            this.newSrNo = nsr.join(', ')
          }
        }
      }
    }
  }
}
</script>
