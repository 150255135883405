<template>
  <b-overlay
    :show="$store.state.hearingStore.hearingLoading"
    rounded="sm"
    variant="transparent"
    opacity="0.5"
    blur="2px"
  >
    <!-- types -->
    <div :type="$store.state.hearingStore.allHearings">
      <div v-for="causelist in $store.state.hearingStore.allHearings">
        <b-card
          no-body
          class="m-0 p-0 rounded-0 mb-3"
          :style="
            isDark
              ? 'background-color: #343d55; border-color: #343d55'
              : 'background-color: #f3f2f7; border-color: #e6e9eb'
          "
          :header="causelist.date"
          header-text-variant="black"
          header-class="m-0 p-1 rounded-0"
        >
          <template #header>
            <b-col class="col-12 text-center">
              <b-row class="justify-content-between">
                <h4 class="text-black">{{ causelist.date }}</h4>
                <div>
                  <export-hearing-by-date
                    :date="causelist.date"
                  ></export-hearing-by-date>
                </div>
              </b-row>
            </b-col>
          </template>

          <b-card no-body class="p-0 m-0 rounded-0">
            <b-table
              :items="causelist.data"
              :fields="fields"
              ref="refInvoiceListTable"
              responsive
              :primary-key="causelist.data._id"
              show-empty
              empty-text="No records found"
              class="position-relative"
            >
              <template #cell(title)="data">
                <h6
                  class="mb-1 text-primary cursor-pointer"
                  v-b-tooltip.hover
                  title="View Matter"
                  @click="
                    $router.push({
                      name: 'dashboard-matter-preview',
                      params: { id: data.item._id }
                    })
                  "
                >
                  {{ data.item.title }}
                </h6>
              </template>
              <!-- Column: Unique Id -->
              <template #cell(unique_id)="data">
                <b-col>
                  <div>
                    <h5 class="font-weight-bold">
                      {{
                        getUniqueId(data.item)
                      }}
                    </h5>
                  </div>
                  <div v-if="Object.keys(data.item).includes('cause_name')">
                    <small v-if="data.item.cause_name">
                      [{{ data.item.cause_name }}]
                    </small>
                  </div>
                </b-col>
              </template>
              <!-- Column: Unique Id -->
              <template #cell(court)="data">
                <b-col>
                  <div v-if="Object.keys(data).includes('ctitle')">
                    <small class="text-secondary font-italic">{{
                      data.ctitle
                    }}</small>
                  </div>
                  <div
                    v-if="
                      Object.keys(data.item).includes('serial_number') ||
                      Object.keys(data.item).includes('court_number')
                    "
                  >
                    <small
                      class="text-primary font-weight-bolder text-uppercase"
                      v-if="
                        data.item.court_number !== null ||
                        data.item.serial_number > 0
                      "
                    >
                      Listed:
                    </small>
                    <small
                      v-if="Object.keys(data.item).includes('serial_number')"
                    >
                      <small
                        class="text-primary font-weight-bolder text-uppercase"
                        v-if="data.item.serial_number > 0"
                      >
                        [ Sr No. {{ data.item.serial_number }} ]
                      </small>
                    </small>
                    <small
                      v-if="Object.keys(data.item).includes('court_number')"
                    >
                      <small
                        class="text-primary font-weight-bolder text-uppercase"
                        v-if="
                          data.item.court_number !== null
                        "
                      >
                        - [ Court No.
                        {{
                          data.item.court_number <= 0
                                      ? getStringCourtNumber(data.item.court_number)
                            : data.item.court_number
                        }}
                        
                      </small>
                    </small>
                    <small
                      v-if="Object.keys(data.item).includes('total_cases')"
                    >
                      <small
                        class="text-primary font-weight-bolder text-uppercase"
                        v-if="
                          data.item.total_cases > 0
                        "
                      >
                        - (
                        {{
                          data.item.total_cases
                        }}
                        ) ]
                      </small>
                    </small>
                  </div>
                  <div v-if="Object.keys(data.item).includes('judges')">
                    <div v-if="data.item.judges">
                      <small
                        class="text-black font-weight-bold"
                        v-if="data.item.judges.length"
                        >{{
                          data.item.hc_name.includes("hc") || data.item.hc_name.includes("sci") ? data.item.judges.length > 1 ? 'JUSTICES' : 'JUSTICE' : ''
                        }}
                        {{ data.item.judges.join(', ') }}
                      </small>
                    </div>
                  </div>
                  <small
                    v-if="
                      Object.keys(data.item).includes('serial_number') &&
                      Object.keys(data.item).includes('court_number')
                    "
                  >
                    <small
                      v-if="
                        (data.court_number !== null) &&
                        data.serial_number > 0
                      "
                    >
                      <hearing-display-court
                        :date="causelist.date"
                        :court_id="data.item.court_id"
                        :court_no="data.item.court_number"
                        :serial_no="data.item.serial_number"
                      ></hearing-display-court>
                    </small>
                  </small>
                </b-col>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-row class="text-nowrap">
                  <b-col cols="auto" class="align-self-center col-8">
                    <div class="text-right">
                      <b-badge
                        variant="primary"
                        pill
                        v-b-tooltip.hover
                        title="View Matter"
                        class="badge-round cursor-pointer mr-1"
                        @click="
                          $router.push({
                            name: 'dashboard-matter-preview',
                            params: { id: data.item._id }
                          })
                        "
                      >
                        <feather-icon
                          :id="`matter-${data.item._id}-preview-icon`"
                          icon="EyeIcon"
                          size="16"
                          class="mr-25"
                        />
                        <span>View</span>
                      </b-badge>
                    </div>
                    <div
                      v-if="Object.keys(data.item).includes('last_order_url')"
                    >
                      <b-badge
                        variant="light"
                        pill
                        class="badge-round mt-2"
                        v-if="data.item.last_order_url"
                        @click="
                          exportJudgment({
                            url: data.item.last_order_url,
                            date: data.item.last_order.datetime,
                            matter_id: data.item.unique_id
                          })
                        "
                      >
                        <feather-icon
                          :id="`order-${data.item._id}-preview-icon`"
                          icon="Link2Icon"
                          size="16"
                          class="mr-25 cursor-pointer text-primary"
                        />
                        <span class="text-primary">Last Order</span>
                      </b-badge>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </b-card>
        </b-card>
      </div>
    </div>
    <b-card
      v-if="
        !$store.state.hearingStore.allHearings.length &&
        !$store.state.hearingStore.hearingLoading
      "
    >
      <p class="text-center mt-5">Record Not Found!</p>
    </b-card>
  </b-overlay>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { VueGoodTable } from 'vue-good-table'
import ExportHearingByDate from './components/ExportHearingByDate.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import HearingDisplayCourt from './components/HearingDisplayCourt.vue'
import moment from 'moment'

import {
  BCard,
  BCardHeader,
  BButton,
  BCollapse,
  BCardBody,
  BTable,
  BBadge,
  BCol,
  BRow,
  BDropdown,
  BDropdownItem,
  BCardText,
  VBTooltip,
  BOverlay
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BCard,
    BCardHeader,
    BButton,
    BCollapse,
    BCardBody,
    BCardText,
    BTable,
    BBadge,
    BCol,
    BRow,
    BDropdown,
    BDropdownItem,
    BOverlay,
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
    ExportHearingByDate,
    HearingDisplayCourt
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {},
  data() {
    let userData = JSON.parse(localStorage.getItem('userData'))

    return {
      interval: null,
      fields: [
        { key: 'unique_id', label: 'Case Ref. Number', sortable: false },
        { key: 'court', label: 'Court', sortable: false },
        { key: 'title', sortable: false },
        { key: 'actions' }
      ],
      court: userData.court.label,
      collapseType: 'border'
    }
  },
  beforeCreate() {
    this.$store.dispatch('hearingStore/fetchHearings')
  },
  beforeMount() {
    this.startInterval()

  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {

    startInterval() {
      const beforeTime = moment('05:00 PM', 'LT').format('HH')
      const afterTime = moment('09:00 AM', 'LT').format('HH')
      const now = moment().format('HH')
      const day = moment().format('dddd')
      console.log(
        day,
        now,
        beforeTime,
        afterTime,
        now > afterTime && now < beforeTime
      )
      if (day !== 'Sunday') {
        if (now > afterTime && now < beforeTime) {
          this.$store.dispatch('displayboardStore/fetchUserDisplayBoard')
          clearInterval(this.interval)
          this.interval = setInterval(
            () =>
              this.$store.dispatch('displayboardStore/fetchUserDisplayBoard'),
            60000
          )
        }
      }
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    exportJudgment({ url, date, matter_id }) {
      let dd = date.toString().split('T')[0]
      let uu = matter_id.includes('-')
        ? matter_id.split('-').slice(2).join('/')
        : matter_id
      let href = url.replace(
        'http://s3.mymunshi.live',
        'https://app.mymunshi.in/exports'
      )
      this.downloadAs(href, `${uu}-order-${dd}.pdf`)
    }
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  }
}
</script>
